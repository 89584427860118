export default function MailIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33341 3.33331H16.6667C17.5834 3.33331 18.3334 4.08331 18.3334 4.99998V15C18.3334 15.9166 17.5834 16.6666 16.6667 16.6666H3.33341C2.41675 16.6666 1.66675 15.9166 1.66675 15V4.99998C1.66675 4.08331 2.41675 3.33331 3.33341 3.33331Z"
        stroke="#457CE9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 5L10.0001 10.8333L1.66675 5"
        stroke="#457CE9"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
