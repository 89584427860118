import { useState } from 'react'
import axios from 'axios'
import './Auth.scss'
import { useDispatch } from 'react-redux'
import { usersActions } from '../../store/user'
import { Link } from 'react-router-dom'

function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

const Auth = () => {
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)

  const [error, setError] = useState({
    email: false,
    password: false,
    emptyPassword: false,
    emptyEmail: false,
  })

  const [authorizationForm, setAuthorizationForm] = useState({
    usernameOrEmail: '',
    password: '',
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handler = {
    changeEmail(e) {
      setAuthorizationForm(prev => ({ ...prev, usernameOrEmail: e.target.value }))
      setError(prev => ({ ...prev, email: false, emptyEmail: false }))
    },
    changePassword(e) {
      setAuthorizationForm(prev => ({ ...prev, password: e.target.value }))
      setError(prev => ({ ...prev, password: false, emptyPassword: false }))
    },
    submitForm() {
      if (authorizationForm.usernameOrEmail.trim() === '') {
        setError(prev => ({ ...prev, emptyEmail: true }))
      } else if (authorizationForm.password.trim() === '') {
        setError(prev => ({ ...prev, emptyPassword: true }))
      } else {
        setIsSubmitting(true)
        axios
          .post(process.env.REACT_APP_BASE_URL + '/api/admin/auth', authorizationForm)
          .then(response => {
            const data = response.data.data
            dispatch(usersActions.changeIsLoggedIn(true))
            dispatch(usersActions.setLoggedInHandler(data.role))
            setCookie('session', data.session, 1)
            setIsSubmitting(false)
          })
          .catch(err => {
            if (err.response.data.message === 'Wrong password') {
              setError(prev => ({ ...prev, password: true }))
            } else if (err.response.data.message === 'Wrong email address or username') {
              setError(prev => ({ ...prev, email: true }))
            }

            setIsSubmitting(false)
          })
      }
    },
  }

  return (
    <div className="auth-content-container">
      <div className="auth-content-modal">
        <div className="auth-welcome">Welcome to</div>
        <div className="auth-images">
          <img src="/static/images/logo.svg" className="auth-logo" alt="Logo" width={106} height={80} />
        </div>
        <h2 className="auth-title">Please, Log in to continue</h2>
        <div className="auth-form-container">
          <div className="form-group">
            <label className="form-label">Username</label>
            <input
              className={`form-control ${(error.email || error.emptyEmail) && 'form-control-error'}`}
              onKeyDown={e => e.keyCode === 13 && handler.submitForm()}
              onChange={handler.changeEmail}
              type="text"
              placeholder="Enter username or email"
              disabled={isSubmitting}
            />
            {error.email && <span className="form-error">Incorrect username</span>}
            {error.emptyEmail && <span className="form-error">Username is required</span>}
          </div>
          <div className="form-group">
            <label className="form-label">Password</label>
            <div className="d-flex" style={{ marginBottom: '8px' }}>
              <input
                style={{ marginBottom: '0px' }}
                className={`form-control ${(error.password || error.emptyPassword) && 'form-control-error'}`}
                onKeyDown={e => e.keyCode === 13 && handler.submitForm()}
                onChange={handler.changePassword}
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter password"
                disabled={isSubmitting}
              />
              {!error.password && !error.emptyPassword && (
                <img
                  onClick={() => setShowPassword(!showPassword)}
                  src="/static/images/show.svg"
                  className="show-password"
                  alt="Show password"
                />
              )}
              {(error.password || error.emptyPassword) && (
                <img src="/static/images/alert.svg" className="show-password" alt="Incorrect password" />
              )}
            </div>
            {error.password && <span className="form-error">Incorrect password</span>}
            {error.emptyPassword && <span className="form-error">Password is required</span>}
          </div>
          <div className="auth-button-container">
            <button className="auth-submit-form-button" disabled={isSubmitting} onClick={handler.submitForm}>
              Log In
            </button>
          </div>

          <div className="auth-privacy-policy">
            I agree to{' '}
            <Link className="auth-privacy-policy-link" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
