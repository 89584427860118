import { useState } from 'react'

const EditAccount = ({ data, dataSetter, action, onSave, onAdd }) => {
  const [error, setError] = useState({
    buyer: false,
    auction: false,
    comment: false,
    password: false,
  })

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="edit-users-form-container">
      <div className="form-group">
        <label className="form-label">Buyer number/email</label>
        <input
          type="text"
          className={`form-control ${error.buyer && 'form-control-error'}`}
          value={data.buyer}
          onChange={e => dataSetter(prev => ({ ...prev, buyer: e.target.value }))}
          placeholder="Enter buyer"
        />
        {error.buyer && <span className="form-error">Buyer is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Auction</label>
        <input
          className={`form-control ${error.auction && 'form-control-error'}`}
          type="text"
          onChange={e => dataSetter(prev => ({ ...prev, auction: e.target.value }))}
          value={data.auction}
          placeholder="Enter auction"
        />
        {error.auction && <span className="form-error">Auction is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Comment</label>
        <input
          className={`form-control ${error.comment && 'form-control-error'}`}
          type="text"
          onChange={e => dataSetter(prev => ({ ...prev, comment: e.target.value }))}
          value={data.comment}
          placeholder="Enter comment"
        />
        {error.comment && <span className="form-error">Comment is required</span>}
      </div>
      {action !== 'add' && (
        <div className="switcher" style={{ cursor: 'pointer' }}>
          <label htmlFor="show-password" style={{ marginRight: '10px', cursor: 'pointer' }}>
            Change password
          </label>
          <input type="checkbox" id="show-password" onChange={e => setShowPassword(e.target.checked)} />
        </div>
      )}
      {(showPassword || action === 'add') && (
        <div className="form-group">
          <label className="form-label">Password</label>
          <input
            type="password"
            className={`form-control ${error.password && 'form-control-error'}`}
            onChange={e => dataSetter(prev => ({ ...prev, password: e.target.value }))}
            value={data.password}
            placeholder="Enter password"
          />
          {error.password && <span className="form-error">Password is required</span>}
        </div>
      )}
      <div className="submit-button-container">
        {action === 'edit' && (
          <button className="save-user-button" onClick={() => onSave()}>
            Save
          </button>
        )}
        {action === 'add' && (
          <button className="save-user-button" onClick={() => onAdd()}>
            Add new
          </button>
        )}
      </div>
    </div>
  )
}

export default EditAccount
