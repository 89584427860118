import React from 'react'
import { Link } from 'react-router-dom'

export default function ContactSubmitted() {
  return (
    <div className="contact">
      <header>
        <Link to="/">
          <img src="/static/images/logo.svg" className="contact-logo" alt="Logo" width={106} height={80} />
        </Link>
      </header>

      <h1 className="contact-title">We’ve got your message - thank you!</h1>

      <p className="contact-response-info">
        We’ll get back to you as soon as possible during our regular business hours:
        <br />
        <br />
        Mon-Fri 9:30 AM - 6:00 PM Eastern Time (USA)
      </p>
    </div>
  )
}
