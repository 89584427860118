import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

const EditAdmin = ({ data, dataSetter, action, onSave, onAdd }) => {
  const [error, setError] = useState({
    username: false,
    name: false,
    surname: false,
    country: false,
    city: false,
    copartAccount: false,
    iaaiAccount: false,
    phone: false,
    email: false,
    role: false,
    password: '',
  })

  const [account, setAccount] = useState({ copart: [], iaai: [] })

  useEffect(() => {
    axios(process.env.REACT_APP_BASE_URL + '/api/accounts').then(response => {
      let accounts = response.data.accounts
      setAccount({ copart: [...accounts.copart], iaai: [...accounts.iaai] })
    })
  }, [])

  return (
    <div className="edit-users-form-container">
      <div className="form-group">
        <label className="form-label">Username</label>
        <input
          type="text"
          className={`form-control ${error.username && 'form-control-error'}`}
          value={data.username}
          onChange={e => dataSetter(prev => ({ ...prev, username: e.target.value }))}
          placeholder="Enter username"
        />
        {error.username && <span className="form-error">Username is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Name</label>
        <input
          className={`form-control ${error.name && 'form-control-error'}`}
          type="text"
          onChange={e => dataSetter(prev => ({ ...prev, name: e.target.value }))}
          value={data.name}
          placeholder="Enter name"
        />
        {error.name && <span className="form-error">Name is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Surname</label>
        <input
          className={`form-control ${error.surname && 'form-control-error'}`}
          type="text"
          onChange={e => dataSetter(prev => ({ ...prev, surname: e.target.value }))}
          value={data.surname}
          placeholder="Enter surname"
        />
        {error.surname && <span className="form-error">Surname is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Password</label>
        <input
          type="password"
          className={`form-control ${error.password && 'form-control-error'}`}
          onChange={e => dataSetter(prev => ({ ...prev, password: e.target.value }))}
          value={data.password}
          placeholder="Enter password"
        />
        {error.password && <span className="form-error">Password is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Country</label>
        <input
          className={`form-control ${error.country && 'form-control-error'}`}
          value={data.country}
          onChange={e => dataSetter(prev => ({ ...prev, country: e.target.value }))}
          type="text"
          placeholder="Enter country"
        />
        {error.country && <span className="form-error">Country is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">City</label>
        <input
          className={`form-control`}
          onChange={e => dataSetter(prev => ({ ...prev, city: e.target.value }))}
          value={data.city}
          type="text"
          placeholder="Enter city"
        />
      </div>
      <div className="form-group">
        <label className="form-label">Copart account</label>
        <select
          value={data.copartAccount}
          className="select"
          onChange={e => dataSetter(prev => ({ ...prev, copartAccount: e.target.value }))}
        >
          <option value="">Copart account</option>
          {account.copart.map(a => {
            return (
              <option key={a.buyer} value={a.buyer}>
                {a.buyer}
              </option>
            )
          })}
        </select>
      </div>
      <div className="form-group">
        <label className="form-label">IAAI account</label>
        <select
          value={data.iaaiAccount}
          className="select"
          onChange={e => dataSetter(prev => ({ ...prev, iaaiAccount: e.target.value }))}
        >
          <option value="">IAAI account</option>
          {account.iaai.map(a => {
            return (
              <option key={a.buyer} value={a.buyer}>
                {a.buyer}
              </option>
            )
          })}
        </select>
      </div>
      <div className="form-group">
        <label className="form-label">Phone</label>
        <input
          className={`form-control ${error.phone && 'form-control-error'}`}
          onChange={e => dataSetter(prev => ({ ...prev, phone: e.target.value }))}
          value={data.phone}
          type="text"
          placeholder="Enter phone"
        />
        {error.phone && <span className="form-error">Phone is required</span>}
      </div>
      <div className="form-group">
        <label className="form-label">Email</label>
        <input
          className={`form-control ${error.email && 'form-control-error'}`}
          onChange={e => dataSetter(prev => ({ ...prev, email: e.target.value }))}
          value={data.email}
          type="text"
          placeholder="Enter email"
        />
        {error.email && <span className="form-error">Email is required</span>}
      </div>
      <div className="checkboxes">
        <div className="checkbox-box">
          <label className="checkbox-label" htmlFor="copart-status">
            Status
          </label>
          <input
            type="checkbox"
            id="copart-status"
            onChange={e => dataSetter(prev => ({ ...prev, status: e.target.checked === true ? 'active' : 'inactive' }))}
            defaultChecked={[data].some(i => i.status === 'active')}
          />
        </div>
      </div>
      <div className="submit-button-container">
        {action === 'edit' && (
          <button className="save-user-button" onClick={() => onSave()}>
            Save
          </button>
        )}
        {action === 'add' && (
          <button className="save-user-button" onClick={() => onAdd()}>
            Add new
          </button>
        )}
      </div>
    </div>
  )
}

export default EditAdmin
