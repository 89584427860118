import { useState } from 'react'
import DataTable from '../../Common/DataTable/DataTable'
import axios from 'axios'
import { useSelector } from 'react-redux'
import './Logs.scss'

const logsHeader = [
  { id: 250, name: 'Username', sortable: true, flex: 1, propName: 'username', isActive: false },
  {
    id: 263,
    name: 'Lot number',
    sortable: true,
    flex: 1,
    propName: 'lotNumber',
    isActive: false,
  },
  {
    id: 253,
    name: 'Auction',
    sortable: true,
    flex: 1,
    propName: 'auction',
    isActive: false,
  },
  {
    id: 251,
    name: 'Bid',
    sortable: true,
    flex: 1,
    propName: 'bid',
    isActive: false,
  },
  { id: 252, name: 'Event', sortable: true, flex: 1, propName: 'event', isActive: false },
  { id: 354, name: 'Buyer number', sortable: true, flex: 1, propName: 'buyer', isActive: false },
  {
    id: 355,
    name: 'Date',
    sortable: true,
    flex: 1,
    propName: 'date',
    isActive: false,
  },
  { id: 352, name: 'IP address', sortable: true, flex: 1, propName: 'ip', isActive: false },
]

const Logs = () => {
  const [keyWord, setKeyWord] = useState('')
  const [data, setData] = useState([])
  const [type, setType] = useState('last-bid')
  const role = useSelector(state => state.user.role)

  const handlers = {
    lot(e) {
      axios
        .get(process.env.REACT_APP_BASE_URL + `/api/bids/history/${type}?lot=${e.target.value}`)
        .then(response => {
          const logs = response.data.data
          setData([...logs])
        })
        .catch(err => {
          console.log(err)
        })
    },
    username(e) {
      axios
        .get(process.env.REACT_APP_BASE_URL + `/api/bids/history/${type}?username=${e.target.value}`)
        .then(response => {
          const logs = response.data.data
          setData([...logs])
        })
        .catch(err => {
          console.log(err)
        })
    },
    vin(e) {
      axios
        .get(process.env.REACT_APP_BASE_URL + `/api/bids/history/${type}?ip=${e.target.value}`)
        .then(response => {
          const logs = response.data.data
          setData([...logs])
        })
        .catch(err => {
          console.log(err)
        })
    },
    buyer(e) {
      axios
        .get(process.env.REACT_APP_BASE_URL + `/api/bids/history/${type}?buyer=${e.target.value}`)
        .then(response => {
          const logs = response.data.data
          setData([...logs])
        })
        .catch(err => {
          console.log(err)
        })
    },
  }

  return (
    <div className="users-table">
      <div className="filters-container" style={{ margin: '20px 0px' }}>
        <input
          type="text"
          className="search-form"
          style={{ width: '230px', margin: '5px' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              setKeyWord(e.target.value)
            }
          }}
          placeholder="Enter keyword"
        />
        <input
          type="text"
          className="search-form"
          style={{ width: '230px', margin: '5px' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              handlers.username(e)
            }
          }}
          placeholder="Enter username"
        />
        <input
          type="text"
          className="search-form"
          style={{ width: '230px', margin: '5px' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              handlers.lot(e)
            }
          }}
          placeholder="Enter lot / stock #"
        />
      </div>
      <div className="filters-container" style={{ margin: '20px 0px' }}>
        <input
          type="text"
          className="search-form"
          style={{ width: '230px', margin: '5px' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              handlers.vin(e)
            }
          }}
          placeholder="Enter IP"
        />
        {role === 'sadmin' && (
          <input
            type="text"
            className="search-form"
            style={{ width: '230px', margin: '5px' }}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handlers.buyer(e)
              }
            }}
            placeholder="Enter buyer"
          />
        )}
        <select
          className="select-form-control"
          onChange={e => {
            setType(e.target.value)
          }}
        >
          <option value="last-bid">Last bid</option>
          <option value="all-bids">All bids</option>
        </select>
      </div>

      <DataTable
        header={logsHeader}
        data={data}
        dataSetter={setData}
        searchKeyword={keyWord}
        showSort={true}
        actions={false}
        hiddenCol={['id', 'winning']}
      />
    </div>
  )
}

export default Logs
