/* eslint-disable */
import React, { Fragment, useState, useCallback, useEffect } from 'react'
import styles from './DataTable.scss'
import downIcon from '../../icons/down.png'

const DataTable = ({
  header,
  data,
  update,
  dataSetter,
  yesIcon,
  editAction,
  deleteAction,
  noIcon,
  hiddenCol,
  searchKeyword,
  actions,
  showSort,
}) => {
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  const [increment, setIncrement] = useState(false)
  const [backup, setBackup] = useState([])
  const [pagination, setPagination] = useState({
    perPage: 10,
    totalCount: data.length,
    currentPage: 1,
    pages: Math.ceil(data.length / 10),
  })

  const [dataToShow, setDataToShow] = useState([...data.slice(0, pagination.perPage)])

  const pageSetter = page => {
    const itemFrom = page === 1 ? 0 : (page - 1) * pagination.perPage
    const itemTo = page === 1 ? pagination.perPage : page * pagination.perPage
    setDataToShow([...data.slice(itemFrom, itemTo)])
  }

  useEffect(() => {
    if (searchKeyword !== '' && typeof searchKeyword !== 'undefined') {
      if (backup.length === 0) setBackup([...data])
      const result = data.filter(p => {
        const s = Object.entries(p).map(c => {
          return c[1]
        })
        let str = s.join('')
        str = str.toLowerCase()
        if (str.includes(searchKeyword.toLowerCase())) return p
      })

      dataSetter([...result])
    } else {
      dataSetter([...backup])
      setBackup([])
    }
  }, [searchKeyword])

  useEffect(() => {
    setPagination({ ...pagination, currentPage: 1, pages: Math.ceil(data.length / 10) })
    setDataToShow([...data.slice(0, pagination.perPage)])
  }, [data])

  const sortTable = (sort, propName) => {
    if (!sort) return
    if (!increment) {
      dataSetter(
        data.sort((a, b) => {
          let x = a[propName]
          let y = b[propName]
          return x < y ? -1 : x > y ? 1 : 0
        })
      )
      setIncrement(true)
    } else {
      dataSetter(
        data.sort((a, b) => {
          let x = a[propName]
          let y = b[propName]
          return x > y ? -1 : x < y ? 1 : 0
        })
      )
      setIncrement(false)
    }
    forceUpdate()
    pageSetter(pagination.currentPage)
  }

  return (
    <Fragment>
      <div className="table-header">
        <ul className="table-header-list">
          {header.map(h => {
            return (
              <li
                key={h.id}
                style={{ flex: h.flex, cursor: h.sortable && showSort ? 'pointer' : 'text' }}
                onClick={() => showSort && sortTable(h.sortable, h.propName)}
              >
                {h.sortable && showSort && <img src={downIcon} alt="sort-arrow" className="sort-icon" />}
                <span className="table-header-text">{h.name}</span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className={styles['table-body']}>
        <ul className={styles['table-body-list']}>
          {dataToShow.map(r => {
            return (
              <li className={`table-row ${r.status === 'Signed up' && 'signed-up'}`} key={r.id}>
                {Object.entries(r).map((c, k) => {
                  if (hiddenCol.includes(c[0])) return
                  if (c[1] === true)
                    return (
                      <span style={{ flex: '1' }} key={k * 100}>
                        <img style={{ flex: '1' }} key={c[0]} src={yesIcon} className="table-icon" alt="yes" />
                      </span>
                    )
                  if (c[1] === false)
                    return (
                      <span style={{ flex: '1' }} key={k}>
                        <img src={noIcon} key={c[0]} className="table-icon" alt="no" />
                      </span>
                    )
                  return (
                    <span style={{ flex: '1' }} key={k}>
                      {c[1]}
                    </span>
                  )
                })}
                {actions && (
                  <span className="actions-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => editAction(r)}
                      style={{ marginRight: '10px' }}
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                      version="1.1"
                      id="Capa_1"
                      width="20px"
                      height="20px"
                      viewBox="0 0 528.899 528.899"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981   c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611   C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069   L27.473,390.597L0.3,512.69z" />
                      </g>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => deleteAction(r)}
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                      version="1.1"
                      id="Capa_1"
                      width="20px"
                      height="20px"
                      viewBox="0 0 408.483 408.483"
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path d="M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316    H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293    c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329    c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355    c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356    c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z" />
                          <path d="M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916    c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z" />
                        </g>
                      </g>
                    </svg>
                  </span>
                )}
              </li>
            )
          })}
          {dataToShow.length === 0 && (
            <li className={`table-row`}>
              <p style={{ textAlign: 'center', width: '100%' }}>Records not found</p>
            </li>
          )}
        </ul>
      </div>
      <div className="pagination-container">
        {pagination.currentPage > 1 && (
          <span
            className="next-page-button"
            onClick={() => {
              pageSetter(pagination.currentPage - 1)
              setPagination(prev => ({
                ...prev,
                currentPage: prev.currentPage - 1,
              }))
            }}
          >
            {'<<'}
          </span>
        )}
        {Array.from(Array(pagination.pages).keys()).map((p, k) => {
          return (
            <span
              key={k * 15}
              className={pagination.currentPage === p + 1 ? 'active-page-number' : undefined}
              onClick={() => {
                setPagination(prev => {
                  return { ...prev, currentPage: p + 1 }
                })
                pageSetter(p + 1)
              }}
            >
              {p + 1}
            </span>
          )
        })}
        {pagination.currentPage < pagination.pages && (
          <span
            className="next-page-button"
            onClick={() => {
              pageSetter(pagination.currentPage + 1)
              setPagination(prev => ({
                ...prev,
                currentPage: prev.currentPage + 1,
              }))
            }}
          >
            {'>>'}
          </span>
        )}
      </div>
    </Fragment>
  )
}

export default DataTable
