import React, { useState } from 'react'
import './Contact.scss'
import PhoneIcon from '../../Common/Icons/Phone'
import MailIcon from '../../Common/Icons/Mail'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'

export default function Contact() {
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  const onNameInput = event => {
    setContactForm(contactForm => ({ ...contactForm, name: event.target.value }))
  }

  const onEmailInput = event => {
    setContactForm(contactForm => ({ ...contactForm, email: event.target.value }))
  }

  const onPhoneInput = event => {
    setContactForm(contactForm => ({ ...contactForm, phone: event.target.value }))
  }

  const onMessageInput = event => {
    setContactForm(contactForm => ({ ...contactForm, message: event.target.value }))
  }

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const history = useHistory()

  const onFormSubmit = event => {
    event.preventDefault()
    setIsSubmitting(true)
    setErrorMessage('')

    axios
      .post('/api/contact', contactForm)
      .then(() => {
        history.push('/contact/success')
      })
      .catch(error => {
        if (error.response.data.message) {
          setErrorMessage(error.response.data.message)
        } else {
          setErrorMessage('Error while trying to send message')
        }

        setIsSubmitting(false)
      })
  }

  return (
    <div className="contact">
      <header>
        <Link to="/">
          <img src="/static/images/logo.svg" className="contact-logo" alt="Logo" width={106} height={80} />
        </Link>
      </header>

      <h1 className="contact-title">We are here to help you</h1>
      <div className="contact-contacts">
        <a className="contact-contacts-item" href="tel:+995322050000">
          <PhoneIcon className="contact-contacts-item-icon" />
          +995322050000
        </a>

        <a className="contact-contacts-item" href="mailto:VSbid999@gmail.com">
          <MailIcon className="contact-contacts-item-icon" />
          VSbid999@gmail.com
        </a>
      </div>

      <form className="contact-form" onSubmit={onFormSubmit}>
        <div className="form-group">
          <label className="form-label">Your name</label>
          <input
            className="form-control"
            value={contactForm.name}
            type="text"
            onInput={onNameInput}
            placeholder="Contact name"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Email</label>
          <input
            className="form-control"
            value={contactForm.email}
            type="email"
            onInput={onEmailInput}
            placeholder="Your email"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Phone</label>
          <input
            className="form-control"
            value={contactForm.phone}
            type="text"
            onInput={onPhoneInput}
            placeholder="Your phone"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Message</label>
          <textarea
            className="form-control form-textarea"
            value={contactForm.message}
            onInput={onMessageInput}
            placeholder="Your message"
            required
          />
        </div>

        <button className="contact-form-submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Send'}
        </button>

        {errorMessage && <div className="contact-form-error-message">{errorMessage}</div>}
      </form>

      <div className="contact-privacy-policy">
        I agree to{' '}
        <Link className="contact-privacy-policy-link" to="/privacy-policy">
          Privacy Policy
        </Link>
      </div>
    </div>
  )
}
