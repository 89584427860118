const usersHeader = [
  { id: 30, name: 'Username', sortable: true, flex: 1, propName: 'username', isActive: false },
  {
    id: 29,
    name: 'Country',
    sortable: true,
    flex: 1,
    propName: 'city',
    isActive: false,
  },
  {
    id: 31,
    name: 'City',
    sortable: true,
    flex: 1,
    propName: 'city',
    isActive: false,
  },
  {
    id: 35,
    name: 'Admin',
    sortable: true,
    flex: 1,
    propName: 'adminName',
    isActive: false,
  },
  { id: 32, name: 'Copart', sortable: true, flex: 1, propName: 'copartStatus', isActive: false },
  {
    id: 33,
    name: 'IAAI',
    sortable: true,
    flex: 1,
    propName: 'delivered',
    isActive: false,
  },
  {
    id: 41,
    name: 'Last bid',
    sortable: true,
    flex: 1,
    propName: 'lastBidDate',
    isActive: false,
  },
  {
    id: 42,
    name: 'User status',
    sortable: true,
    flex: 1,
    propName: 'status',
    isActive: false,
  },
  {
    id: 43,
    name: 'Actions',
    sortable: false,
    flex: 0.5,
    propName: 'none',
    isActive: false,
  },
]

export default usersHeader
