import { Link } from 'react-router-dom'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <Link className="privacy-policy-logo" to="/">
        <img src="/static/images/logo.svg" alt="Logo" width={106} height={80} />
      </Link>
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <p className="privacy-policy-date">Effective Date: July 23, 2024</p>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Introduction</h2>

        <div className="privacy-policy-section-text">
          <span style={{ fontWeight: 500 }}>VSBID</span> ("we," "our," "us") values your privacy. This Privacy Policy
          outlines how we collect, use, disclose, and protect your Information when you use our Google Chrome extension
          ("Extension"). By using our Extension, you agree to the terms of this Privacy Policy.
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Information we Collect</h2>

        <div className="privacy-policy-section-text">
          <ol className="privacy-policy-ol">
            <li className="privacy-policy-ol-item">
              <h3 className="privacy-policy-ol-item-title">Personal Information:</h3>

              <div className="privacy-policy-ol-item-text">
                We do not collect personal Information directly from you through the Extension.
              </div>
            </li>

            <li>
              <h3 className="privacy-policy-ol-item-title">Cookies and Tracking Technologies:</h3>
              <ul className="privacy-policy-ul">
                <li className="privacy-policy-ul-item">
                  We use cookies to improve your experience with our Extension. These cookies help us understand how you
                  interact with our Extension, allowing us to enhance performance and usability.
                </li>
                <li className="privacy-policy-ul-item">
                  Third-Party Cookies: Our Extension may allow you to visit third-party websites, which may use cookies
                  or other tracking technologies over which we have no control. These third parties may collect data
                  about your browsing activities across different websites.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Use of Information</h2>

        <div className="privacy-policy-section-text">
          We use the Information collected via our cookies to:
          <ul className="privacy-policy-ul">
            <li className="privacy-policy-ul-item">Improve the functionality and performance of our Extension.</li>
            <li className="privacy-policy-ul-item">Analyze usage patterns to enhance user experience.</li>
          </ul>
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Third-Party Websites</h2>

        <div className="privacy-policy-section-text">
          Our Extension may contain links to third-party websites. This Privacy Policy does not apply to the privacy
          practices of those third-party websites. We are not responsible for the content or privacy practices of such
          websites. We encourage you to review the privacy policies of any third-party websites you visit.
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Disclosure of Information</h2>

        <div className="privacy-policy-section-text">
          We do not sell, trade, or otherwise transfer your information to outside parties. We may share It only if
          required by law or to protect our rights.
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Data Security</h2>

        <div className="privacy-policy-section-text">
          We implement reasonable security measures to protect your Information from unauthorized access, use, or
          disclosure. However, internet or email transmission is only partially secure and error-free.
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Your Choices</h2>

        <div className="privacy-policy-section-text">
          You can choose to disable cookies through your browser settings. However, disabling cookies may affect the
          functionality of our Extension.
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Changes to This Privacy Policy</h2>

        <div className="privacy-policy-section-text">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page. Your continued use of the Extension after any changes signifies your acceptance
          of the updated Privacy Policy.
        </div>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-section-title">Contact Us</h2>

        <div className="privacy-policy-section-text">
          If you have any questions or concerns about this Privacy Policy, please contact us at: <br />
          <br />
          VSBID <br />
          support@vsbid.com
        </div>
      </section>
    </div>
  )
}

export default PrivacyPolicy
